<section style="background-image:url(assets/img/home-six/page-bg.png); margin-bottom: 25px;"
    class="banner-section pt-200 pb-75">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title text-center">
                    <h1>{{'Ozellikler'|translate}}</h1>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="service-section-one another-page pt-65 pb-45 bg-gray">
    <div class="container">
        <div class="row" style="text-align: justify; text-justify: inter-word;">



            <div class="col-lg-4 col-md-6">
                <div class="single-service service-style-one text-center wow  customFadeInUp delay-0-1s animated"
                    style="visibility: visible; animation-name: customFadeInUp;">
                    <div class="service-icon zero">
                        <i class="fa fa-birthday-cake"></i>
                    </div>
                    <div class="service-content">
                        <h5>{{'KapaliVeAcikEtkinlikler'|translate}}</h5><br />
                        <p>{{'KapaliVeAcikEtkinlikler.Aciklama'|translate}}</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-service service-style-one text-center wow  customFadeInUp delay-0-1s animated"
                    style="visibility: visible; animation-name: customFadeInUp;">
                    <div class="service-icon zero">
                        <i class="fa fa-portrait"></i>
                    </div>
                    <div class="service-content">
                        <h5>{{'EtkinlikSahibi'|translate}}</h5><br />
                        <p>{{'EtkinlikSahibi.Aciklama'|translate}}</p>
                    </div>
                </div>
            </div>



            <div class="col-lg-4 col-md-6">
                <div class="single-service service-style-one text-center wow  customFadeInUp delay-0-1s animated"
                    style="visibility: visible; animation-name: customFadeInUp;">
                    <div class="service-icon zero">
                        <i class="fas fa-images"></i>
                    </div>
                    <div class="service-content">
                        <h5>{{'KatilimciYuklemeleri'|translate}}</h5><br />
                        <p>{{'KatilimciYuklemeleri.Aciklama'|translate}}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
