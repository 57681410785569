import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ozellikler',
  templateUrl: './ozellikler.component.html'
})
export class OzelliklerComponent implements OnInit {
  constructor(
    private srvTitle:Title,
  ) { 
    //srvTitle.setTitle("PhotoFace - Özellikler")
  }

  ngOnInit(): void {

  }

}
