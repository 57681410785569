
<section style="margin-top: 200px;" class="error-section another-page pt-75 pb-30">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="error-text">
                    <h1>404</h1>
                    <h2>Opps!! Gitmeye çalıştığınız sayfa bulunamadı.</h2>
                </div>
                <div class="home-or-search">
                    <a [routerLink]="['/anasayfa']" class="btn" style="background-color: #4C656A; color: white;">Anasayfa</a>
                </div>
            </div>
        </div>
    </div>
</section>