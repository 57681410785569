import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {

  constructor(
    private srvTitle:Title,
  ) { 
    //srvTitle.setTitle("PhotoFace - 404")
  }

  ngOnInit(): void {
  }

}
