import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { SozlesmeDialogComponent } from '../dialog/sozlesme-dialog/sozlesme-dialog.component';

@Component({
  selector: 'app-gizlilik',
  templateUrl: './gizlilik.component.html'
})
export class GizlilikComponent implements OnInit {
  constructor(
    private srvTitle: Title,
    private dialog: MatDialog
  ) {
    //srvTitle.setTitle("PhotoFace - Gizlilik")
  }

  ngOnInit(): void {

  }

  sozlesmeDialog() {

    this.dialog.open(SozlesmeDialogComponent, {
      width: '60%',
      data: {}
    });
  }
}
