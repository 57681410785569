<section style="background-image:url(assets/img/home-six/page-bg.png); margin-bottom: 25px;"
    class="banner-section pt-200 pb-75">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title text-center">
                    <h1>{{'Cozumler'|translate}}</h1>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="con-cloud-service-one pt-100 rpt-90 pb-100 rpb-60 text-center">
    <div class="container">
        <div class="row">


            <div class="col-lg-3">
                <div class="con-cloud-single-service-one">
                    <div class="image">
                        <img src="assets/img/home-four/wedding2.png" alt="Service">
                    </div>
                    <h4>{{'DugunlerVeNisanlar'|translate}}</h4>
                    <p>{{'DugunlerVeNisanlar.Aciklama'|translate}}</p>
                </div>
            </div>


            <div class="col-lg-3">
                <div class="con-cloud-single-service-one">
                    <div class="image">
                        <img src="assets/img/home-four/corporate2.png" alt="Service">
                    </div>
                    <h4>{{'KurumsalEtkinlikler'|translate}}</h4>
                    <p>{{'KurumsalEtkinlikler.Aciklama'|translate}}</p>
                </div>
            </div>


            <div class="col-lg-3">
                <div class="con-cloud-single-service-one">
                    <div class="image">
                        <img src="assets/img/home-four/sport2.png" alt="Service">
                    </div>
                    <h4>{{'SporEtkinlikleri'|translate}}</h4>
                    <p>{{'SporEtkinlikleri.Aciklama'|translate}}</p>
                </div>
            </div>


            <div class="col-lg-3">
                <div class="con-cloud-single-service-one">
                    <div class="image">
                        <img src="assets/img/home-four/dip.png" style="height:150px;width:150px;" alt="Service">
                    </div>
                    <h4>{{'Mezuniyetler'|translate}}</h4>
                    <p>{{'Mezuniyetler.Aciklama'|translate}}</p>
                </div>
            </div>



            <div class="col-lg-3">
                <div class="con-cloud-single-service-one">
                    <div class="image">
                        <img src="assets/img/home-four/res.png" style="height:150px;width:150px;" alt="Service">
                    </div>
                    <h4>{{'TatilKoyleri'|translate}}</h4>
                  <p>{{'TatilKoyleri.Aciklama'|translate}}</p>
                </div>
            </div>



            <div class="col-lg-3">
                <div class="con-cloud-single-service-one">
                    <div class="image">
                        <img src="assets/img/home-four/team.png" style="height:150px;width:150px;" alt="Service">
                    </div>
                    <h4>{{'Kongreler'|translate}}</h4>
                    <p>{{'Kongreler.Aciklama'|translate}}</p>
                </div>
            </div>


            <div class="col-lg-3">
                <div class="con-cloud-single-service-one">
                    <div class="image">
                        <img src="assets/img/home-four/party.png" style="height:150px;width:150px;" alt="Service">
                    </div>
                    <h4>{{'OzelPartiler'|translate}}</h4>
                    <p>{{'OzelPartiler.Aciklama'|translate}}</p>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="con-cloud-single-service-one">
                    <div class="image">
                        <img src="assets/img/home-four/festival2.png" alt="Service">
                    </div>
                    <h4>{{'Festivaller'|translate}}</h4>
                    <p>{{'Festivaller.Aciklama'|translate}}</p>
                </div>
            </div>

        </div>
    </div>
</section>
