<footer class="pt-200 pb-30 text-center">
    <div class="container">
        <div class="hp6-footer-middle mt-95">
            <ul class="hp6-footer-menu">
                <li><a [routerLink]="['/anasayfa']">{{'Anasayfa'| translate}}</a></li>
                <li><a [routerLink]="['/ozellikler']">{{'Ozellikler'|translate}}</a></li>
                <li><a [routerLink]="['/cozumler']">{{'Cozumler'|translate}}</a></li>
                <li><a [routerLink]="['/gizlilik']">{{'Gizlilik'|translate}}</a></li>
                <li><a [routerLink]="['/fiyatlandirma']">{{'Fiyatlandirma'|translate}}</a></li>
            </ul>
            <ul class="social-icon-two">
                <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fa fa-instagram"></i></a></li>
            </ul>
        </div>
        <!--Copyright-->
        <div class="hp6-footer-bottom">

        <div class="hp6-footer-top">
            <img src="assets/img/logo/hp6.png" alt="Footer Logo">
        </div>
            <div style="color: #4C656A;" class="copyright">
                Copyright @{{ yil }} PhotoFace. {{'TumHaklari'|translate}} <a href="https://larinsoft.com/" target="_blank">LarinSoft</a>'{{'Aittir'|translate}}
            </div>
        </div>
    </div>
</footer>
