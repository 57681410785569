<section style="background-image:url(assets/img/home-six/page-bg.png); margin-bottom: 25px;"
         class="banner-section pt-200 pb-75">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="page-title text-center">
          <h1>{{'Fiyatlandirma'|translate}}</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="hp6-who-we-are mt-100">
  <div class="container">
    <div class="hp6-section-title text-center">
      <h5 style="color: #000; font-size: 26px; font-weight: bold;">
        {{'PaketiniziSecin'|translate}}
      </h5><br>

      <p style="color: #000; font-size: 15px;">
        {{'PaketiniziSecin.Aciklama'|translate}}
      </p>
      <span class="line"></span>
    </div>
  </div>
</section>
<div class="container">
  <!--pricing buttons-->
 <!-- <div class="currency-tab">
    <div class="btn-group dropleft">
      <span class="dropdown-toggle" style="margin-top: 8px;color: black"></span>
      <h5 style="padding-top: 13px">Pricing</h5>
      <button type="button" class="currency " data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
        {{currency}}
      </button>

      <div class="dropdown-menu">

          <div class="d-flex flex-row" >
            <button class="currency" (click)="euroClick()" *ngIf="currency!=euro">€</button>
            <button class="currency" (click)="dollarClick()"*ngIf="currency!=dollar">$</button>
            <button class="currency" (click)="sterlinClick()"*ngIf="currency!=sterlin">£</button>
          </div>

      </div>
    </div>
  </div>-->
  <!--pricing buttons-->
  <div class="row" style="margin-top:150px">

    <div class="col-lg-4">
      <div class="card mb-5 mb-lg-0 shadow mb-5 bg-white" style="border-radius:20px">
        <div class="card-body">

          <h5 class="card-title text-muted text-uppercase text-center">500 {{'Fotograf'|translate}}</h5>
          <!--<h5 class="d-flex justify-content-center align-items-center"><div class="price">{{priceSmall}} {{currency}}</div></h5>-->
          <hr>
          <ul class="fa-ul">
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span><strong>{{'FotografYukleme500'|translate}}</strong>
            </li>
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span>{{'LimitsizKatilimci'|translate}}</li>
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span>{{'LimitsizEtkinlik'|translate}}</li>
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span>{{'TumOzellikler'|translate}}</li>
          </ul>
          <p style="text-align: center; margin-top: 50px;"> {{'IdealKucuk'|translate}}</p>
          <a target="_blank" href="https://online.photofaceapp.com/#/"
             class="btn btn-block" style="background-color: #4C656A; color: white;">{{'Kayit'|translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card mb-5 mb-lg-0 shadow mb-5 bg-white" style="border-radius:20px">
        <div class="card-body">
          <h5 class="card-title text-muted text-uppercase text-center">2000 {{'Fotograf'|translate}} </h5>
          <!--<h5 class="d-flex justify-content-center align-items-center"><div class="price">{{priceMedium}} {{currency}}</div></h5>-->
          <hr>
          <ul class="fa-ul">
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span><strong
            >{{'FotografYukleme2000'|translate}}</strong></li>
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span>{{'LimitsizKatilimci'|translate}}</li>
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span>{{'LimitsizEtkinlik'|translate}}</li>
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span>{{'TumOzellikler'|translate}}</li>
          </ul>
          <p style="text-align: center; margin-top: 50px;"> {{'IdealOrta'|translate}}</p><a target="_blank"
                                                                                            href="https://online.photofaceapp.com/#/"
                                                                                            class="btn btn-block"
                                                                                            style="background-color: #4C656A; color: white;">{{'Kayit'|translate}}</a>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card mb-5 mb-lg-0 shadow mb-5 bg-white" style="border-radius:20px">
        <div class="card-body">
          <h5 class="card-title text-muted text-uppercase d-flex justify-content-center align-items-center ">5000 {{'Fotograf'|translate}} </h5>
       <!--   <h5 class="d-flex justify-content-center align-items-center"><div class="price">{{priceLarge}} {{currency}}</div></h5>-->
          <hr>
          <ul class="fa-ul">
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span><strong
            >{{'FotografYukleme5000'|translate}}</strong></li>
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span>{{'LimitsizKatilimci'|translate}}</li>
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span>{{'LimitsizEtkinlik'|translate}}</li>
            <li><span class="fa-li"><i
              class="fas fa-check" aria-hidden="true"></i></span>{{'TumOzellikler'|translate}}</li>
          </ul>
          <p style="text-align: center; margin-top: 50px;"> {{'IdealBuyuk'|translate}}</p>
          <p style="text-align: center;"></p><a target="_blank"
                                                href="https://online.photofaceapp.com/#/" class="btn btn-block"
                                                style="background-color: #4C656A; color: white;">{{'Kayit'|translate}}</a>
        </div>
      </div>

    </div>

  </div>
  <div class="text-dark d-flex justify-content-center align-items-center">
    <p style="font-weight:bold;font-size: large ">{{'PriceInform'|translate}} <a
      href="mailto:support@photofaceapp.com">support@photofaceapp.com</a> {{'PriceInform.Mail'|translate}}</p>
  </div>
</div>
