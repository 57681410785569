import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AnasayfaComponent } from './components/anasayfa.component';
import { NotFoundComponent } from './components/not-found.component';
import { OzelliklerComponent } from './components/ozellikler.component';
import { GizlilikComponent } from './components/gizlilik.component';
import { CozumComponent } from './components/cozum.component';
import { FiyatlandirmaComponent } from './fiyatlandirma/fiyatlandirma.component';
import { IndirmelerComponent } from './components/indirmeler/indirmeler.component';
import {GetAppComponent} from "./components/get-app/get-app.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AnasayfaComponent,
  },
  {
    path: 'anasayfa',
    component: AnasayfaComponent,
  },
  {
    path: 'ozellikler',
    component: OzelliklerComponent,
  },
  {
    path: 'gizlilik',
    component: GizlilikComponent,
  },
  {
    path: 'indirmeler',
    component: IndirmelerComponent,
  },
  {
    path: 'cozumler',
    component: CozumComponent,
  },
  {
    path: 'fiyatlandirma',
    component: FiyatlandirmaComponent,
  },
  {path: 'get-app', component: GetAppComponent},
  {
    path: '**',
    component: NotFoundComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
