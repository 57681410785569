<section style="background-image:url(assets/img/home-six/page-bg.png); margin-bottom: 25px;"
    class="banner-section pt-200 pb-75">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title text-center">
                    <h1>{{'Indirmeler'|translate}}</h1>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="row" style="margin-top:150px">
        <div class="col-lg-6 mb-sm-5 mb-md-5">
            <div  class="card mb-5 mb-lg-0 shadow mb-5 bg-white" style="border-radius:20px">
                <div class="card-body">
                    <h3 class="card-title text-uppercase text-center">{{'OrganizatorlerIcin'|translate}}</h3>
                    <hr>
                    <div class="row mb-10 d-flex justify-content-around">
                        <a href="../../../resources/downloads/PFResizer.dmg" download="PFResizer.dmg">
                            <img src="../../../assets/store-logos/macos-download.png" style="width: 200px;">
                        </a>
                        <a href="../../../resources/downloads/pfresizer.zip" download="pfresizer.zip">
                            <img src="../../../assets/store-logos/windows-download.png" style="width: 200px;">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card mb-5 mb-lg-0 shadow mb-5 bg-white" style="border-radius:20px">
                <div class="card-body">
                    <h3 class="card-title text-uppercase text-center">{{'KatilimcilarIcin'|translate}}</h3>
                    <hr>
                    <div class="row mb-10 d-flex justify-content-around">
                        <a target="blank" href="https://apps.apple.com/tr/app/photoface/id1608552866"><img src="../../../assets/store-logos/app-store.png" style="width: 200px;"></a>
                        <a target="blank" href="https://play.google.com/store/apps/details?id=com.larinsoft.photoface"><img src="../../../assets/store-logos/google-play.png" style="width: 200px;"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
