<header class="main-header">
    <!--Header-Upper-->
    <div class="header-upper">
        <div class="container clearfix">
            <div class="header-inner clearfix  d-xl-flex">
                <div class="logo-outer">
                    <div class="logo">
                        <a [routerLink]="['/anasayfa']"><img src="assets/img/logo/hp6.png" alt="" title=""></a>
                    </div>
                    <div class="fixed-logo">
                        <a [routerLink]="['/anasayfa']"><img src="assets/img/logo/hp6.png" alt="" title=""></a>
                    </div>
                </div>
                <!-- Main Menu -->
                <nav class="main-menu navbar-expand-md">
                    <div class="navbar-header clearfix">
                        <div class="logo">
                            <a [routerLink]="['/anasayfa']"><img src="assets/img/logo/hp6.png" alt="" title=""></a>
                        </div>
                        <div class="fixed-logo">
                            <a [routerLink]="['/anasayfa']"><img src="assets/img/logo/hp6.png" alt="" title=""></a>
                        </div>
                        <!-- Toggle Button -->
                        <button type="button" class="navbar-toggle" data-toggle="collapse"
                            data-target=".navbar-collapse-one">
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </button>
                    </div>
                    <div class="navbar-collapse navbar-collapse-one collapse clearfix">
                        <ul class="navigation clearfix">
                            <li><a [routerLink]="['/anasayfa']">{{'Anasayfa'| translate}}</a></li>
                            <li><a [routerLink]="['/ozellikler']">{{'Ozellikler'|translate}}</a></li>
                            <li><a [routerLink]="['/cozumler']">{{'Cozumler'|translate}}</a></li>
                            <li><a [routerLink]="['/gizlilik']">{{'Gizlilik'|translate}}</a></li>
                            <li><a [routerLink]="['/fiyatlandirma']">{{'Fiyatlandirma'|translate}}</a></li>
                            <li ><a [routerLink]="['/indirmeler']">{{'Indirmeler'|translate}}</a></li>
                          <li><a target="_blank" href="https://online.photofaceapp.com/#/">{{'Giris'|translate}}</a></li>

                          <!--                        dilsecimi-->
                          <li class="nav-item">
                            <a  class="dropdown-toggle dropdown" type="button" style="cursor: pointer" id="navbarDropdown"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              {{'SelectLanguage'|translate}}
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                              <a class="dropdown-item" *ngFor="let lang of supportedLanguages" style="list-style-type: none ;cursor: pointer" (click)="selectLanguage(lang.code)">
                                <i
                                  class="navbar-toggler-icon mx-2"
                                  style="border-radius: 50% ;background-image: url({{lang.url}}) ;background-size: cover;
								background-position: center center; "></i><span

                                class="active">{{lang.code |translate}}</span>
                              </a>
                            </div>
                          </li>

                          <!--                        dilsecimibitis-->

                        </ul>

                    </div>

                </nav>
            <!--  <div class="d-flex ">

                <button class="btn btn-success" style="background-color: #4C656A; color: white;" target="_blank"
                        href="https://online.photofaceapp.com/#/">{{'Giris'|translate}}</button>

              </div>-->
            </div>
        </div>
    </div>

    <!--End Header Upper-->
</header>
