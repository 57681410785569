<div role="main" class="main" style="overflow-x: hidden; overflow-y: scroll; max-height:550px;  ">

  <div class="container pt-5" align="justify">


    <h2 style="text-align: center;"><b>{{'Sozlesme.Baslik'|translate}}</b></h2>
    <br>
    <h4>{{'Sozlesme.H1'|translate}}</h4>

    <p><strong>1.1 </strong>{{'BirTarafta'|translate}}
      <strong>{{'Sozlesme.Adres'|translate}}</strong> {{'AdresindeBulunan'|translate}} <strong>Larinsoft Bilişim
        Teknoloji A.Ş.</strong> ({{'BundanBoyle'|translate}} <strong>
        {{'Sirket'|translate}}</strong> {{'OlarakAnılacaktır'|translate}}) {{'IleDigerTarafta'|translate}} <strong>xxxxxxxx</strong> {{'AdresindeYerlesik'|translate}}
      <strong>xxx</strong>({{'BundanBoyle'}}
      <strong>{{'Uye'}}</strong> {{'OlarakAnılacaktır'|translate}}) {{'Sozlesme.H2'|translate}}
      <strong>“{{'Sozlesme.Baslik'}}</strong> {{'TanzimImza'|translate}}
    </p>
    <p><strong>1.2</strong> {{'Sozlesme.H3'|translate}}</p>
    <h4>2. {{'Tanimlar'|translate}}</h4>
    <h4>3. {{'Sozlesme.Konu'|translate}}</h4>
    <p><strong>3.1</strong> {{'UyeTarafından'|translate}} <a
      href="https://photofaceapp.com/">www.photofaceapp.com.tr</a> {{'Sozlesme.H4'|translate}}</p><br>
    <h4>4. {{'Sozlesme.H5'|translate}}</h4>
    <p><strong>4.1</strong>{{'Sozlesme.H6'|translate}}</p><br>
    <p><strong>4.2</strong> {{'Sozlesme.H7'|translate}}</p><br>
    <p><strong>4.3</strong>{{'Sozlesme.H8'|translate}}</p>
    <p><strong>4.4</strong> {{'Sozlesme.H9'|translate}}</p>
    <p><strong>4.5</strong>{{'Sozlesme.H10'|translate}}</p>
    <p><strong>4.6</strong>{{'Sozlesme.H11'|translate}}</p>
    <p><strong>4.7</strong>{{'Sozlesme.H12'|translate}}</p>
    <p><strong>4.8</strong>{{'Sozlesme.H13'|translate}}</p>
    <p><strong>4.9</strong> {{'Sozlesme.H14'|translate}}</p>
    <p><strong>4.10</strong>{{'Sozlesme.H15'|translate}}</p>
    <p><strong>4.11</strong>{{'Sozlesme.H16'|translate}}</p>
    <p><strong>4.12</strong>{{'Sozlesme.H17'|translate}}</p>
    <p><strong>4.13</strong>{{'Sozlesme.H18'|translate}}</p>
    <p><strong>4.14</strong>{{'Sozlesme.H19'|translate}}</p>
    <p><strong>4.15</strong>{{'Sozlesme.H20'|translate}}</p>
    <p><strong>4.16</strong>{{'Sozlesme.H21'|translate}}</p>
    <p><strong>4.17</strong>{{'Sozlesme.H22'|translate}}</p>
    <p><strong>4.18</strong>{{'Sozlesme.H23'|translate}}</p>
    <ol>
      <li>{{'Sozlesme.A'|translate}}</li>
      <li>{{'Sozlesme.B'|translate}}</li>
      <li>{{'Sozlesme.C'|translate}}</li>

    </ol>
    <p>{{'Sozlesme.C2'|translate}}</p>
    <ol>
      <li>
        {{'Sozlesme.D'|translate}}
      </li>
      <li>
        {{'Sozlesme.E'|translate}}
      </li>

      <li>
        {{'Sozlesme.F'|translate}}
      </li>
      <li>
        {{'Sozlesme.G'|translate}}
      </li>
    </ol>
    <p>
      {{'Sozlesme.G2'|translate}}
    </p>


    <p>{{'HaklarinaSahipsiniz'|translate}}</p>
    <p><strong>4.19</strong>{{'Sozlesme.H24'|translate}}</p>
    <h4>{{'Sozlesme.H25'|translate}}</h4>
    <p>{{'Sozlesme.H26'|translate}}<strong>{{'Uye'|translate}}</strong> {{'Tarafından'|translate}} <strong>{{'Sirket'|translate}}’e </strong>{{'Sozlesme.H27'|translate}}</p>
    <br>
    <br>
    <div class=" d-flex justify-content-center">
      <table border="0" class="table-hover col-md-9">
        <tr>
          <td><b>{{'SiraNo'|translate}}</b></td>
          <td><b>{{'HizmetKonusu'|translate}}</b></td>
          <td><b>{{'UyelikBedeli'|translate}}</b></td>
          <td><b>{{'Odeme'|translate}}</b></td>
        </tr>
        <br>
        <tr>
          <td>
            <p>1</p>
          </td>
          <td>
            <p>{{'LarinsoftUyelikHizmeti'|translate}}</p>
          </td>
          <td>
            <p> XXX TL+KDV</p>
          </td>
          <td>
            <p><strong>{{'Sozlesme'|translate}}</strong>{{'Sozlesme.H28'|translate}}
              <strong>{{'Sirket'|translate}}</strong>TR08 0006 2000 5970 0006 2968 69  {{'Sozlesme.H29'|translate}}
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h4>{{'Sozlesme.H30'|translate}}</h4>
    <p>{{'Sozlesme.H31'|translate}}</p>
    <ol start="7">
      <li>{{'Sozlesme.H32'|translate}}</li>
    </ol>
    <ol>
      <li>{{'Sozlesme.H33'|translate}}

      </li>
    </ol>
    <h4>{{'Sozlesme.H34'|translate}}</h4>
    <p>{{'Sozlesme.H35'|translate}}<strong>{{'Sozlesme'|translate}}</strong>{{'Sozlesme.H36'|translate}} <strong>{{'Sozlesme'|translate}}</strong>{{'Sozlesme.H37'|translate}}</p>
    <br>
    <h4>{{'Sozlesme.H38'|translate}}</h4>
    <p>{{'Sozlesme.H39'|translate}}</p>
    <h4>{{'Sozlesme.H40'|translate}}</h4>
    <p>{{'Sozlesme.H41'|translate}}</p>
    <br>
    <p>{{'Sozlesme.H42'|translate}}<b>{{'Sozlesme'|translate}}</b>{{'Sozlesme.H43'|translate}}</p>
    <br>
    <p>{{'Sozlesme.H44'|translate}}</p>
    <br>
    <p>{{'Sozlesme.H45'|translate}}</p>
    <br><br>
    <div class="d-flex justify-content-center">
      <table border="0" class="table-hover col-md-9">
        <thead>
        <tr>
          <td colspan="2">
            <b>{{'Sirket.Adina'|translate}}</b>
          </td>
          <br>
          <td colspan="2">
            <b>{{'Uye.Adina'|translate}}</b>
          </td>
          <br>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            {{'Isim'|translate}}
          </td>
          <td>

          </td>
          <td>
            {{'Isim'|translate}}
          </td>
          <td>

          </td>
        </tr>
        <tr>
          <td>
            {{'Tarih'|translate}}
          </td>
          <td>

          </td>
          <td>
            {{'Tarih'|translate}}
          </td>
          <td>

          </td>
        </tr>
        <tr>
          <td>
            {{'Imza'|translate}}
          </td>
          <td>

          </td>
          <td>
            {{'Imza'|translate}}
          </td>
          <td>

          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

</div>
