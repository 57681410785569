import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-app',
  templateUrl: './get-app.component.html',
  styleUrls: ['./get-app.component.css']
})
export class GetAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.redirect();

  }
  redirect() {
    const platform = this.androidOrIOS();
    if (platform === undefined) {window.location.replace('https://photofaceapp.com/'); }
    if (platform === 'ios') {window.location.replace('https://apps.apple.com/us/app/photoface/id1608552866'); }
    if (platform === 'android') {window.location.replace('https://play.google.com/store/apps/details?id=com.larinsoft.photoface&gl=US'); }
  }

  androidOrIOS() {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      return 'android';
    }
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
      return 'ios';
    }
    return undefined;
  }
}
