
<section class="hero-section hp6-hero py-185 text-center"
    style="background-image:url(assets/img/home-six/bg.png); margin-bottom: 25px;">
    <div class="container">
        <div class="hp6-hero-content mb-40">
            <h1 class="wow customFadeInDown slow delay-0-6">{{'Anasayfa.Baslik'|translate}}</h1>
            <p style="color: #000; font-size: 20px;" class="wow customFadeInDown slow delay-0-4s">
              {{'Anasayfa.Aciklama1'|translate}}<span
                    style="font-weight:bold; font-size: 28px;">PhotoFace</span> {{'IleTanisin'|translate}} {{'Anasayfa.Aciklama2'|translate}}
            </p>
        </div>
        <div class="hp6-hero-images" style="position: relative;padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden;width: 100%; height: 100%; ">
            <div class="hp6-hero-middle wow customFadeInDown slow">
              <iframe
               style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; "
                src="https://www.youtube.com/embed/UmL6ddbOOAA"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
        </div>
    </div>
</section>
<section class="hp6-who-we-are mt-100 mb-150 rmb-90">
    <div class="container">
        <div class="hp6-section-title mb-55 text-center">
            <h2>{{'NasilCalisir'|translate}}</h2>
            <span class="line"></span>
        </div>
        <mat-tab-group mat-stretch-tabs>
            <mat-tab label="{{'OrganizatorlerIcin'|translate}}">
                <div style="margin-top: 50px;" class="row align-items-center">
                    <div class="col-lg-12 order-lg-2">
                        <div class="hp6-wwa-content ml-35 rml-0 rmb-50">
                            <div class="hp6-section-title mb-20">
                                <h2>{{'HerseyKolay'|translate}}</h2>
                                <span class="line"></span>
                            </div>
                            <p>
                              {{'HerseyKolay.Aciklama'|translate}}
                            </p>
                        </div>
                    </div>

                </div>
                <div class="row align-items-center">

                    <div class="col-lg-12 order-lg-2">
                        <div class="hp6-wwa-content ml-35 rml-0 rmb-50">
                            <div class="hp6-section-title mb-20">
                                <h2>{{'FotograflariYukleyin'|translate}}</h2>
                                <span class="line"></span>
                            </div>
                            <p>
                              {{'FotograflariYukleyin.Aciklama'|translate}}
                            </p>
                        </div>
                    </div>

                </div>
                <div style="margin-top: 10px;" class="row align-items-center">
                    <div class="col-lg-12 order-lg-2">
                        <div class="hp6-wwa-content ml-35 rml-0 rmb-50">
                            <div class="hp6-section-title mb-20">
                                <h2>{{'FotografSatisi'|translate}}</h2>
                                <span class="line"></span>
                            </div>
                            <p>
                              {{'FotografSatisi.Aciklama'|translate}}
                            </p>
                        </div>
                    </div>

                </div>
            </mat-tab>
            <mat-tab label="{{'KatilimcilarIcin'|translate}}">
                <div style="margin-top: 50px;" class="row align-items-center">
                    <div class="col-lg-6 order-lg-2">
                        <div class="hp6-wwa-content ml-35 rml-0 rmb-50">
                            <div class="hp6-section-title mb-20">
                                <h2>{{'EtkinligeKatilin'|translate}}</h2>
                                <span class="line"></span>
                            </div>
                            <p>
                              {{'EtkinligeKatilin.Aciklama'|translate}}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="hp6-wwa-img">
                            <img src="assets/img/home-six/etkinlik.png" alt="Who We Are">
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="hp6-wwd-content mr-35 rmr-0 rmb-50">
                            <div class="hp6-section-title mb-20">
                                <h2>{{'PhotoFaceVeSelfie'|translate}}</h2>
                                <span class="line"></span>
                            </div>
                            <p>
                              {{'PhotoFaceVeSelfie.Aciklama'|translate}}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="hp6-wwd-img">
                            <img src="assets/img/home-six/selfie.png" alt="What We Do">
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="hp6-bg-shape-one">
        <img src="assets/img/home-six/bg-shpae-1.png" alt="Backgrund Shape">
    </div>
    <div class="hp6-bg-shape-three">
        <img src="assets/img/home-six/bg-shpae-3.png" alt="Backgrund Shape">
    </div>
</section>
<style>
    ::ng-deep .mat-tab-body-content {
        overflow: hidden !important;
    }
</style>
