<section style="background-image:url(assets/img/home-six/page-bg.png); margin-bottom: 25px;"
    class="banner-section pt-200 pb-75">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title text-center">
                    <h1>{{'Gizlilik'|translate}}</h1>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="hp6-who-we-are mt-100">
    <div class="container">
        <div class="hp6-section-title text-center">
            <p style="color: #000; font-size: 20px;">
                <span style="font-weight:bold; font-size: 22px;">PhotoFace</span> {{'Gizlilik.Aciklama'|translate}}
            </p>
            <p (click)="sozlesmeDialog()" style="cursor:pointer;color: #000; font-size: 16px;">
                <span style="font-weight:bold; font-size: 18px;">{{'UyelikVeGizlilik'|translate}}</span> {{'Tos'|translate}}<a>{{'Tiklayin'|translate}}</a>.
            </p>
            <span class="line"></span>
        </div>
    </div>
</section>
<section class="my-skills pt-50">
    <div class="container">

        <div class="row">
            <div class="col-lg-3">
                <div class="accordion-left">
                    <div class="accordion-image">
                        <img src="assets/img/skill/accordion.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-lg-9">
                <!--Accordion Box / Style One-->
                <div class="accordion-box accordion-box-one">

                    <div class="accordion accordion-block wow animated customFadeInUp delay-0-1s">
                        <div class="accord-btn active">
                            <h5>{{'MobilUygulama'|translate}}</h5>
                        </div>
                        <div class="accord-content collapsed">
                            <p>{{'MobilUygulama.Aciklama'|translate}}</p>
                        </div>
                    </div>

                    <div class="accordion accordion-block wow animated customFadeInUp delay-0-2s">
                        <div class="accord-btn active">
                            <h5>{{'QrKod'|translate}}</h5>
                        </div>
                        <div class="accord-content collapsed">
                            <p>{{'QrKod.Aciklama'|translate}}</p>
                        </div>
                    </div>

                    <div class="accordion accordion-block wow animated customFadeInUp delay-0-2s">
                        <div class="accord-btn active">
                            <h5>{{'VeriGuvenligi'|translate}}</h5>
                        </div>
                        <div class="accord-content collapsed">
                            <p>
                              {{'VeriGuvenligi.Aciklama'|translate}}
                            </p>
                        </div>
                    </div>

                    <div class="accordion accordion-block wow animated customFadeInUp delay-0-2s">
                        <div class="accord-btn active">
                            <h5>{{'FotografSahibi'|translate}}</h5>
                        </div>
                        <div class="accord-content collapsed">
                            <p>{{'FotografSahibi.Aciklama'|translate}}
                            </p>
                        </div>
                    </div>

                    <div class="accordion accordion-block wow animated customFadeInUp delay-0-2s">
                        <div class="accord-btn active">
                            <h5>{{'YuzTanimaIcinYapayZeka'|translate}}</h5>
                        </div>
                        <div class="accord-content collapsed">
                            <p>{{'YuzTanimaIcinYapayZeka.Aciklama'|translate}}</p>
                        </div>
                    </div>

                    <div class="accordion accordion-block wow animated customFadeInUp delay-0-2s">
                        <div class="accord-btn active">
                            <h5>{{'GuvenlikDestegi'|translate}}</h5>
                        </div>
                        <div class="accord-content collapsed">
                            <p>{{'GuvenlikDestegi.Aciklama'|translate}}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
