import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {EmbedVideoService} from 'ngx-embed-video';

@Component({
  selector: 'app-anasayfa',
  templateUrl: './anasayfa.component.html'
})
export class AnasayfaComponent implements OnInit {
  youtubeurl = 'https://www.youtube.com/watch?v=UmL6ddbOOAA';
  TTitle: string;

  constructor(private readonly titleService: Title,
              private readonly translate: TranslateService,
              private embedService: EmbedVideoService
  ) {
    console.log(this.embedService.embed(this.youtubeurl));

    this.translate.get('Title').subscribe((res) => {
      this.titleService.setTitle(res);
    });
  }

  ngOnInit(): void {

  }

}
