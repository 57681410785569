import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './indirmeler.component.html',
  styleUrls: ['./indirmeler.component.css'],
})
export class IndirmelerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
