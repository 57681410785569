import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './partial/header/header.component';
import { FooterComponent } from './partial/footer/footer.component';
import { AnasayfaComponent } from './components/anasayfa.component';
import { NotFoundComponent } from './components/not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressSpinnerService } from './service/progress-spinner-service.service';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { OzelliklerComponent } from './components/ozellikler.component';
import { GizlilikComponent } from './components/gizlilik.component';
import { CozumComponent } from './components/cozum.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FiyatlandirmaComponent } from './fiyatlandirma/fiyatlandirma.component';
import { SozlesmeDialogComponent } from './dialog/sozlesme-dialog/sozlesme-dialog.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {IndirmelerComponent} from './components/indirmeler/indirmeler.component';
import {EmbedVideo} from 'ngx-embed-video';
import { GetAppComponent } from './components/get-app/get-app.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AnasayfaComponent,
    NotFoundComponent,
    OzelliklerComponent,
    GizlilikComponent,
    CozumComponent,
    SozlesmeDialogComponent,
    FiyatlandirmaComponent,
    IndirmelerComponent,
    GetAppComponent
  ],
  entryComponents: [SozlesmeDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    OverlayModule,
    MatSnackBarModule,
    MatTabsModule,
    MatDialogModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    EmbedVideo.forRoot()
  ],
  providers: [ProgressSpinnerService],
  bootstrap: [AppComponent],
})
export class AppModule {}
