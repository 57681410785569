import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cozum',
  templateUrl: './cozum.component.html'
})
export class CozumComponent implements OnInit {
  constructor(
    private srvTitle:Title,
  ) { 
    //srvTitle.setTitle("PhotoFace - Çözümler")
  }

  ngOnInit(): void {

  }

}
