import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-fiyatlandirma',
  templateUrl: './fiyatlandirma.component.html',
  styleUrls: ['./fiyatlandirma.component.css']
})
export class FiyatlandirmaComponent implements OnInit {

  priceSmall = 5;
  priceMedium = 10;
  priceLarge = 15;
  currency: string;
  euro = '€';
  dollar = '$';
  sterlin = '£';

  constructor(private readonly translateService: TranslateService) { }

  ngOnInit(): void {
    this.currency = this.euro;
  }

  euroClick(){
    this.currency = this.euro;
    this.priceSmall = 5;
    this.priceMedium = 10;
    this.priceLarge = 15;
  }
  dollarClick(){
    this.currency = this.dollar;
    this.priceSmall = 7;
    this.priceMedium = 12;
    this.priceLarge = 17;
  }
  sterlinClick(){
    this.currency = this.sterlin;
    this.priceSmall = 9;
    this.priceMedium = 19;
    this.priceLarge = 29;
  }

}
