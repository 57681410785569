import {Component, OnInit} from '@angular/core';
import { supportedLanguages } from 'src/app/config/supportedLanguages';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  readonly supportedLanguages = supportedLanguages;

  constructor(private readonly  translateService: TranslateService) {}

  ngOnInit(): void {}


  public selectLanguage(lang: string) {
    console.log(lang);
    localStorage.setItem('lang', lang);
    this.translateService.use(lang);
    window.location.reload();
  }

  public getlang() {

    const currentLang = this.translateService.currentLang;
    let lang;
    this.translateService.get(currentLang).subscribe((res) => {
      lang = res;
    });
    return lang;
  }



}
